<template>
  <Layout>
    <BHeader>
      <template #right>
        <el-button
          type="prrmary"
          @click="EditItem()"
        >
          新增
        </el-button>
      </template>
    </BHeader>
    <el-table
      v-loading="formData.loading"
      :data="formData.incentiveList"
      style="width: 100%"
    >
      <el-table-column
        label="序号"
        prop="rank"
      />
      <el-table-column
        label="广告名称"
        prop="name"
      />
      <el-table-column
        label="广告ID"
        prop="admob_id"
      />
      <el-table-column
        label="应用ID"
        prop="names"
      />
      <el-table-column
        label="来源标记"
        prop="remark"
      />
      <el-table-column
        label="状态"
        prop="categoryName"
        width="110px"
      />
      <el-table-column
        label="累计赠送金币"
        prop="total_golds"
      />
      <el-table-column
        label="操作"
      >
        <template #default="{row}">
          <BTextButton
            title="编辑"
            icon="fa fa-pencil"
            @click="EditItem(row)"
          />
          <BTextButton
            :title="row.status === 1 ? '禁用' : '启用'"
            :icon="`fa ${row.status === 1 ? 'fa-check-circle' : 'fa-ban' }`"
            @click="handleToggleRowState(row)"
          />
          <!-- <BTextButton
            :loading="row.deleteLoading"
            title="删除"
            icon="fa fa-trash-o"
            @click="handleDeleteItem(row)"
          /> -->
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :total="formData.total"
      :page.sync="formData.page"
      :limit.sync="formData.pageSize"
      @pagination="getActivityTasks"
    />
  </Layout>
</template>

<script>
import {
  getActivityTasks,
  updateActivityTasks
} from '@/api/incentive-advertising.js'
import { commonRequestGet } from '@/api/commonApi.js'
import { categoryStateListOne } from '@/utils/selectOptions.js'

export default {
  components: {

  },
  data () {
    return {
      categoryStateListOne,
      formData: {
        status: '', // 状态
        loading: false,
        incentiveList: [],
        page: 1,
        pageSize: 20,
        total: 0
      },
      appKeysNameMap: [],
      categoryStateList1: []
    }
  },
  created () {
    this.getAppKeys()
    this.getActivityTasks()
    this.categoryStateList1 = categoryStateListOne // 赋值为全局变量categoryStateList
  },
  methods: {
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    },

    // 表格数据
    getActivityTasks () {
      getActivityTasks(
        {
          page: this.formData.page,
          pageSize: this.formData.pageSize
        }
      ).then(res => {
        console.log(res, '---------res')
        this.formData.total = res.total
        this.formData.incentiveList = res.data.map(item => {
          // 找到匹配的name
          const categoryState = item.status
          const statusname = this.categoryStateList1.find(key => key.value === categoryState)
          const categoryName = statusname ? statusname.name : ''

          let names = '' // 默认名称或空字符串
          if (item.app_key !== null) {
            names = this.appKeysNameMap[item.app_key] || ''
          }
          return {
            ...item,
            names: names,
            categoryName: categoryName
          }
        })
      })
        .finally(() => {
          this.formData.loading = false
        })
    },

    // 新增,编辑
    EditItem (row) {
      if (row) {
        this.$router.push({
          name: 'incentive-edit',
          query: { row: row }
        })
      } else {
        this.$router.push({ name: 'incentive-edit' })
      }
    },

    // 上架下架
    handleToggleRowState (row) {
      const param = {
        status: row.status
      }
      const id = row.id
      const targetStatus = param.status === 1 ? 0 : 1
      const tips = targetStatus === 1 ? '此操作将启用，是否继续?' : '此操作将禁用，是否继续?'

      this.$confirm(tips, '提示', { type: 'warning' })
        .then(async () => {
          try {
            this.$set(row, 'status', targetStatus)
            await updateActivityTasks(id, { status: targetStatus })
            this.getActivityTasks()
          } catch (err) {
            this.$message.error(err.response.data.message)
            this.getActivityTasks()
          }
        })
    },
    handleDeleteItem (row) {
      this.$confirm('此操作将删除该广告, 是否继续', '提示', {
        type: 'warning '
      }).then(() => {
        // this.$set(row, 'deleteLoading', true)
        // deleteAdvItem({
        //   id: row.id
        // })
        //   .then(res => {
        //     if (res.code === 200) {
        //       this.$message.success('删除成功')
        //       this.getListData()
        //     }
        //   })
        //   .finally(() => {
        //     this.$set(row, 'deleteLoading', false)
        //   })
      })
    }

  }

}
</script>

<style scoped lang="scss">
.img {
  display: flex;
  .el-image{
    width: 50px;
    height: 50px;
    margin-right: 20px;
  }
}
</style>
